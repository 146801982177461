<template>
  <div id="container" class="mymap"></div>
</template>

<script>
import AMap from "AMap";
import { getSchedulePositions } from "@/api/lib/api.js";

export default {
  props: {
    driverOrderId: {},
  },
  data () {
    return {
      map: {},
      title: "",
      markers: [],
    };
  },

  methods: {
    loadmap () {
      this.map = new AMap.Map("container", {
        mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
        zoom: 5,
        features: ["bg", "road", "point"],
      });
      getSchedulePositions({ driverOrderId: this.driverOrderId }).then(
        (res) => {
          if (res.code === "SUCCESS") {
            this.markers = [];
            res.data.forEach((element) => {
              let marker = new AMap.Marker({
                position: new AMap.LngLat(
                  element.startPointLongitude,
                  element.startPointLatitude
                ),
                icon:
                  element.type == 1
                    ? require("../../../../assets/images/home/car.png")
                    : require("../../../../assets/images/home/passenger.png"),
                label:
                  element.type == 1
                    ? {}
                    : {
                      offset: new AMap.Pixel(0, 5),
                      content:
                        "<div class='info'>" + "上车点-" +
                        element.validPassengersNum +
                        "人-" +
                        element.phone +
                        "</div>",
                      direction: "top",
                    },
                title: element.type == 1 ? "司机" : "乘客",
              });
              this.map.add(marker);
              this.markers.push(marker);
              if (element.endPointLongitude) {
                let marker2 = new AMap.Marker({
                  position: new AMap.LngLat(
                    element.endPointLongitude,
                    element.endPointLatitude
                  ),
                  icon: require("../../../../assets/images/home/passenger.png"),
                  label: {
                    offset: new AMap.Pixel(0, 5),
                    content:
                      "<div class='info'>" + "下车点-" + element.validPassengersNum + "人-" +
                      element.phone +
                      "</div>",
                    direction: "top",
                  },
                  title: "乘客",
                });
                this.map.add(marker2);
                this.markers.push(marker2);
              }
            });
            this.map.setFitView(this.markers);
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },

    clearMap () {
      this.map.clearMap();
    },
  },
  mounted () {
    this.loadmap(); // 加载地图和相关组件;
  },
};
</script>

<style>
.mymap {
  width: 100%;
  height: 60vh;
}
</style>
